<template>
  <div class="search__container">
    <div class="search__wrap">
      <div class="search__header">
        <el-form class="search__form" @submit.native.stop="onFormSubmit">
          <div class="search__input-box">
            <div class="control">
              <!-- <i class="icon el-icon-search" /> -->
              <input
                ref="inputRef"
                v-model="inputValue"
                class="input"
                :placeholder="firstSearchValue"
              >

              <i class="icon el-icon-search" @click="onFormSubmit" />
            </div>
            <!-- <div class="mask" @click="onClickInputMask">
              <div class="wrap" :class="{ left: !isShowMask, show: !isShowInput }">
                <i class="icon el-icon-search" />
                <span class="tip">{{ firstSearchValue || '输入关键词' }}</span>
              </div>
            </div> -->
          </div>

          <!-- <div v-if="isShowHotkey && (keywordList.length - 1)" class="search__hot-word">
            <dl class="word">
              <dt>
                <i class="icon el-icon-tickets" />
                搜索历史：
              </dt>
              <dd v-for="item in keywordList.slice(1)" :key="item.id">
                <a href="javascript:void(0)" @click.stop="onClickWordBtn(item.word)">{{ item.word }}</a>
              </dd>
            </dl>
          </div> -->

          <!-- 该按钮为键入 enter 提交表单的必要条件 -->
          <button class="submit-button" type="submit">Submit</button>
        </el-form>

        <dl v-if="historyList.length" class="search__history">
          <dt class="name">
            <i class="icon el-icon-tickets" />
            {{ $t('search.search_histories') }}：

          </dt>
          <dd
            v-for="(item, index) in historyList"
            :key="item"
            class="item"
            :class="{ first: index === 0 }"
            @click.stop="onClickWordBtn(item)"
          >
            <a href="javascript:void(0)">
              <i class="index">{{ index + 1 }}</i>{{ item }}
            </a>
          </dd>
        </dl>
      </div>

      <div class="search__body">
        <GoodsList class="search__goods-list" :data="goodsList" />

        <Pagination :data="pageData" @current-change="onPageChange" />
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, ref, toRefs, computed, watch, onMounted } from '@vue/composition-api'
import GoodsList from '@/components/Common/GoodsList.vue'
import Pagination from '@/components/Common/Pagination.vue'
import useCodeInLifeCycle from '@/composition/useCodeInLifeCycle.js'

import { getDomainPrefix } from '@/utils'
import { useRequest } from '@/utils/request'
import { Message } from 'element-ui'

export default {
  name: 'Search',

  components: {
    GoodsList,
    Pagination
  },

  setup(_, { root }) {
    const store = root.$store
    const $jst = root.$jst

    const inputRef = ref(null)
    const inputValue = ref('')
    const firstSearchValue = ref($jst('search.keyword_required'))

    // 是否展示输入框
    const isShowInput = ref(true)
    // 是否展示 mask
    const isShowMask = ref(false)

    const refreshFunc = computed(() => store.state.app.refreshModelFunc)

    const data = reactive({
      isShowHotkey: false,

      pageData: {
        pageNo: 1,
        pageSize: process.env.VUE_APP_PAGE_SIZE,
        total: 0
      },

      goodsList: [],

      historyList: store.state.app.histories || [],

      modelList: computed(() => store.state.app.template),
      keywordList: [],

      currencyId: computed(() => root.$store.state.app.currencyItem?.id),
      countryId: computed(() => root.$store.state.app.countryItem?.id),

      isSearched: false
    })

    onMounted(() => {
      refreshFunc.value()
    })

    const onClickInputMask = _ => {
      if (inputRef.value) {
        inputRef.value.focus()
      }

      isShowMask.value = true

      window.setTimeout(() => {
        isShowInput.value = false
      }, 300)
    }

    const { data: goodsListData, fetch } = useRequest('common/getGoodsList', {
      data: computed(() => ({
        pageNo: data.pageData.pageNo,
        pageSize: data.pageData.pageSize,
        domainPrefix: getDomainPrefix, // process.env.VUE_APP_SHOP_DOMAIN,
        keyword: inputValue.value.trim(),
        currencyId: data.currencyId,
        countryId: data.countryId
      })),

      immediate: false
    })

    watch(() => data.currencyId, _ => {
      if (data.isSearched) {
        if (data.currencyId && data.countryId) {
          fetch()
        }
      }
    })

    watch(() => data.countryId, _ => {
      if (data.isSearched) {
        if (data.currencyId && data.countryId) {
          fetch()
        }
      }
    })

    const onClickInputControl = e => {
      e.stopPropagation()
    }

    const onFormSubmit = e => {
      e.preventDefault()
      if (!inputRef.value.value.trim()) return Message.error($jst('search.keyword_required'))

      data.pageData.pageNo = 1

      store.commit('app/SET_HISTORY', inputValue.value || firstSearchValue.value)

      fetch()
    }

    const onClickWordBtn = word => {
      inputValue.value = word

      isShowMask.value = true

      window.setTimeout(() => {
        isShowInput.value = false
      }, 300)

      fetch()
    }

    useCodeInLifeCycle(() => {
      const fn = () => {
        if (!inputValue.value.trim()) {
          isShowMask.value = false
          isShowInput.value = true
        }
      }
      document.documentElement.addEventListener('click', fn)

      return () => {
        document.documentElement.removeEventListener('click', fn)
      }
    })

    watch(goodsListData, () => {
      data.goodsList = goodsListData.value.records
      data.pageData.total = goodsListData.value.total

      data.isSearched = true
    })

    const onPageChange = page => {
      data.pageData.pageNo = page

      fetch()
    }

    const { data: keywordObj, fetch: hotwordFetch } = useRequest('home/getHotwordList', {
      immediate: false
    })

    watch(() => data.modelList, () => {
      if (data.modelList.some(current => current.modelType === 1)) {
        data.isShowHotkey = true

        hotwordFetch()
      }
    })

    watch(() => keywordObj.value, result => {
      data.isShowHotkey = true
      // 找出第一个数据
      data.keywordList = Object.values(result).find(current => current) || []

      if (data.keywordList.length) {
        firstSearchValue.value = data.keywordList[0].word
      }
    })

    return {
      inputRef,
      isShowInput,
      inputValue,
      isShowMask,
      firstSearchValue,
      ...toRefs(data),

      onFormSubmit,
      onClickInputControl,
      onClickInputMask,
      onPageChange,
      onClickWordBtn
    }
  }
}
</script>
<style lang="scss" scoped>
.search__container {
  // padding: 44px 0;

  .search__wrap {
    min-width: 1200px;
    width: 96.9%;
    max-width: 1600px;
    // padding: 127px 0 0;
    margin: 0 auto;

  }

  .search__header {
    position: relative;
    padding-top: 127px;
    padding-bottom: 40px;
    z-index: 5;
    background: #fff;

    .search__form {
      width: 806px;
      margin: 0 auto;
      .search__input-box {
        position: relative;
        border-bottom: 2px solid #0D5CAB;
        margin-bottom: 68px;
        overflow: hidden;
        cursor: text;

        @mixin icon {
          display: inline-block;
          width: 40px;
          line-height: 44px;
          font-size: 42px;
          font-weight: normal;
          text-align: center;
          vertical-align: middle;
          color: #2F6BBB;
        }

        .control {
          display: flex;
          width: 100%;
          padding: 0 23px 27px;
          box-shadow: 0 0 5px #fff;

          .icon {
            @include icon;
          }

          .input {
            width: 100%;
            height: 44px;
            border: none;
            outline: none;
            font-size: 24px;
            line-height: 44px;
            color: #0D5CAB;
            background: #ffffff;

          }
        }

        .mask {
          position: absolute;
          width: 100%;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;

          .wrap {
            position: absolute;
            left: 10px;
            transform: translateX(0);
            transition: .3s all cubic-bezier(0, 1.01, .66, 1);

            &.left {
              left: 50%;
              opacity: 1;
              transform: translateX(-50%);
            }

            &.show {
              opacity: 0;
            }
          }

          .icon {
            @include icon;
            line-height: 44px;
          }

          .tip {
            font-size: 16px;
            line-height: 26px;
            vertical-align: middle;
            color: #555555;
          }
        }

      }

      .search__hot-word {
        margin-top: 12px;
        .word {
          margin: 0;

          font-size: 12px;
          line-height: 26px;

          dt {
            display: inline-block;
            color: #0049AC;
          }

          dd {
            display: inline-block;
            margin: 0;

            a {
              color: #444444;
            }

            & + dd::before {
              content: '|';
              display: inline-block;
              margin: 0 5px;
            }
          }
        }
      }

      .submit-button {
        display: none;
      }
    }
  }

  .search__body {
    box-shadow: 0px 17px 102px 15px rgba(232, 232, 234, 0.95);

    .search__goods-list {
      padding: 70px 0 163px;
      background: #F8F9FA;
    }
  }

  .search__history {
    width: 806px;
    padding-bottom: 134px;
    margin: 32px auto 0;

    .name {
      // display: inline-block;
      font-size: 16px;
      font-weight: bolder;
      line-height: 26px;
      color: #222222;

      .icon {
        display: inline-block;
        margin-right: 13px;
        margin-bottom: 22px;
        color: #0D5CAB;
      }
    }

    .item {
      display: inline-block;
      border-radius: 12px;
      margin: 0 12px 0 0;
      line-height: 25px;
      font-size: 14px;
      line-height: 26px;
      // color: #222;
      // background: #EEEEEE;

      &.first {
        a {
          color: #0D5CAB;

          .index {

            color: #fff;
            background: #0D5CAB;
          }
        }
      }

      a {
        display: block;
        padding: 0 12px 0 0;
        font-size: 15px;
        color: #666666;

        .index {
          display: inline-block;
          width: 20px;
          height: 20px;
          border-radius: 2px;
          margin-right: 11px;
          font-size: 14px;
          line-height: 20px;
          text-align: center;
          font-style: normal;
          color: #666666;
          background: #EFEFF1;
        }
      }
    }
  }
}
</style>
